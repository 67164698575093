<template>
  <div class="page-wrapper">
    <!-- Hero -->
    <section id="accueil" class="hero">
      <img src="@/assets/img/serre-toi-hero-banner.jpg" alt="" class="bg">

      <figure class="thumb" data-inview="fadeInUp" data-delay="100">

        <img src="@/assets/img/serre-toi-hero-thumb.png" alt="Les Fruites et légumes de Serre du Québec." title="Les Fruites et légumes de Serre du Québec.">

        <svg data-inview="fadeInUp" data-delay="300" class="svg-txt" id="Group_91" data-name="Group 91" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 461.621 275.244">
          <path id="Path_64" data-name="Path 64" d="M-1639.027,238.9h9.928V281.8h8.406v9.5h-18.333Z" transform="translate(1639.027 -229.915)" fill="#efc"/>
          <path id="Path_65" data-name="Path 65" d="M-1617.054,238.9h19.128v9.133h-9.2v11.4h7.711v9.134h-7.711v13.591h9.2V291.3h-19.128Z" transform="translate(1638.424 -229.915)" fill="#efc"/>
          <path id="Path_66" data-name="Path 66" d="M-1594.672,279.692v-6.284h9.994v6.321c0,1.826.529,2.667,1.555,2.667,1.257,0,1.753-1.279,1.753-3.215v-1.827c0-3.47-.562-4.676-2.25-6.284l-5.625-5.3c-3.939-3.691-5.395-7.234-5.395-13.153v-.476c0-9.317,4.534-13.993,11.947-13.993,6.784,0,11.385,4.311,11.385,12.788v4.019h-9.928v-4.786c0-1.571-.431-2.522-1.49-2.522-1.125,0-1.72.841-1.72,2.851v.694c0,2.558.463,4.019,2.052,5.626l5.526,5.517c3.574,3.543,5.659,6.722,5.659,14.066v1.79c0,9.133-3.906,13.81-11.98,13.81C-1590.668,292-1594.672,287.146-1594.672,279.692Z" transform="translate(1637.809 -229.894)" fill="#efc"/>
          <path id="Path_67" data-name="Path 67" d="M-1558.485,238.9h18.333v9.133h-8.406v11.838h7.776v9.316h-7.776V291.3h-9.928Z" transform="translate(1636.816 -229.915)" fill="#efc"/>
          <path id="Path_68" data-name="Path 68" d="M-1536.342,238.9h12.079c7.843,0,11.483,3.654,11.483,13.08,0,8.147-2.515,11.581-6.818,12.677,5.395.8,6.718,2.851,6.718,7.636v19h-9.994V272.406c0-2.449-.728-3.361-3.541-3.361V291.3h-9.928Zm10.325,21.556c2.384,0,3.21-1.9,3.21-6.211v-1.682c0-3.507-.7-5.078-3.243-5.078h-.364v12.97Z" transform="translate(1636.208 -229.915)" fill="#efc"/>
          <path id="Path_69" data-name="Path 69" d="M-1508.55,280.553V238.9h9.995v41.76c0,1.169.562,2.046,1.654,2.046s1.654-.877,1.654-2.046V238.9h9.994v41.65c0,7.088-3.938,11.472-11.648,11.472C-1504.578,292.026-1508.55,287.641-1508.55,280.553Z" transform="translate(1635.445 -229.915)" fill="#efc"/>
          <path id="Path_70" data-name="Path 70" d="M-1480.588,238.9h9.928V291.3h-9.928Z" transform="translate(1634.678 -229.915)" fill="#efc"/>
          <path id="Path_71" data-name="Path 71" d="M-1461.1,248.4h-6.122v-9.5h22.172v9.5h-6.122V291.3h-9.928Z" transform="translate(1634.311 -229.915)" fill="#efc"/>
          <path id="Path_72" data-name="Path 72" d="M-1442.7,279.692v-6.284h9.994v6.321c0,1.826.53,2.667,1.555,2.667,1.258,0,1.755-1.279,1.755-3.215v-1.827c0-3.47-.563-4.676-2.25-6.284l-5.626-5.3c-3.938-3.691-5.394-7.234-5.394-13.153v-.476c0-9.317,4.534-13.993,11.947-13.993,6.783,0,11.384,4.311,11.384,12.788v4.019h-9.928v-4.786c0-1.571-.43-2.522-1.489-2.522-1.125,0-1.721.841-1.721,2.851v.694c0,2.558.464,4.019,2.052,5.626l5.526,5.517c3.575,3.543,5.659,6.722,5.659,14.066v1.79c0,9.133-3.9,13.81-11.98,13.81C-1438.7,292-1442.7,287.146-1442.7,279.692Z" transform="translate(1633.637 -229.894)" fill="#efc"/>
          <path id="Path_73" data-name="Path 73" d="M-1406.511,238.9h19.127v9.133h-9.2v11.4h7.71v9.134h-7.71v13.591h9.2V291.3h-19.127Z" transform="translate(1632.644 -229.915)" fill="#efc"/>
          <path id="Path_74" data-name="Path 74" d="M-1378.348,248.4h-6.122v-9.5h22.172v9.5h-6.122V291.3h-9.928Z" transform="translate(1632.039 -229.915)" fill="#efc"/>
          <path id="Path_75" data-name="Path 75" d="M-1350.426,238.9h9.928V281.8h8.4v9.5h-18.333Z" transform="translate(1631.104 -229.915)" fill="#efc"/>
          <path id="Path_76" data-name="Path 76" d="M-1328.453,238.649h19.127v9.133h-9.2v11.4h7.71v9.134h-7.71v13.591h9.2v9.134h-19.127Zm6.354-8.988h9.8l-2.151,7.088h-9.034Z" transform="translate(1630.501 -229.661)" fill="#efc"/>
          <path id="Path_77" data-name="Path 77" d="M-1305.868,279.948V250.682c0-7.6,4.4-12.531,12.145-12.531,7.446,0,11.748,4.86,11.748,12.094V256.2h-10.259v-6.722c0-1.279-.5-2.192-1.654-2.192s-1.654.913-1.654,2.192v30.434c0,2.083,1.091,2.741,2.548,2.741a3.108,3.108,0,0,0,1.555-.365V272.02h-2.781v-8.914h12.377v25.721c-2.35,1.754-6.817,3.178-11.085,3.178C-1301.5,292-1305.868,287.767-1305.868,279.948Z" transform="translate(1629.881 -229.894)" fill="#efc"/>
          <path id="Path_78" data-name="Path 78" d="M-1277.43,280.553V238.9h9.995v41.76c0,1.169.562,2.046,1.654,2.046s1.655-.877,1.655-2.046V238.9h9.993v41.65c0,7.088-3.938,11.472-11.648,11.472C-1273.458,292.026-1277.43,287.641-1277.43,280.553Z" transform="translate(1629.101 -229.915)" fill="#efc"/>
          <path id="Path_79" data-name="Path 79" d="M-1249.467,238.9h13.667l2.879,34.635h.066l2.88-34.635h13.535V291.3h-8.6V255.453h-.066l-3.376,35.842h-8.936L-1240.8,256h-.067V291.3h-8.6Z" transform="translate(1628.333 -229.915)" fill="#efc"/>
          <path id="Path_80" data-name="Path 80" d="M-1211.331,238.9h19.128v9.133h-9.2v11.4h7.711v9.134h-7.711v13.591h9.2V291.3h-19.128Z" transform="translate(1627.286 -229.915)" fill="#efc"/>
          <path id="Path_81" data-name="Path 81" d="M-1188.949,279.692v-6.284h9.994v6.321c0,1.826.53,2.667,1.555,2.667,1.258,0,1.755-1.279,1.755-3.215v-1.827c0-3.47-.563-4.676-2.25-6.284l-5.626-5.3c-3.938-3.691-5.394-7.234-5.394-13.153v-.476c0-9.317,4.534-13.993,11.947-13.993,6.783,0,11.384,4.311,11.384,12.788v4.019h-9.928v-4.786c0-1.571-.43-2.522-1.489-2.522-1.125,0-1.721.841-1.721,2.851v.694c0,2.558.464,4.019,2.052,5.626l5.526,5.517c3.575,3.543,5.659,6.722,5.659,14.066v1.79c0,9.133-3.9,13.81-11.98,13.81C-1184.945,292-1188.949,287.146-1188.949,279.692Z" transform="translate(1626.672 -229.894)" fill="#efc"/>
          <path id="Path_82" data-name="Path 82" d="M-1638.091,317.3h18.247c12.482,0,19.041,7.18,19.041,19.149v45.477c0,12.143-6.241,19.09-20.627,19.09h-16.661Zm16.661,68.83c2.909,0,4.231-1.225,4.231-5.079V336.912c0-3.5-1.534-4.729-4.178-4.729h-.846v53.943Z" transform="translate(1639.001 -232.067)" fill="#efc"/>
          <path id="Path_83" data-name="Path 83" d="M-1593.514,317.3h30.57v14.6h-14.7v18.215h12.323V364.7h-12.323v21.718h14.7v14.6h-30.57Z" transform="translate(1637.778 -232.067)" fill="#efc"/>
          <path id="Path_84" data-name="Path 84" d="M-1543.243,376.792h28.613c0,5.955,2.856,8.7,7.246,8.7,3.544,0,6.082-1.752,6.082-5.663,0-4.2-2.8-6.071-8.25-8.172l-10.472-4.029c-12.746-4.9-22.478-10.682-22.478-25.4,0-19.207,15.866-26.855,35.118-26.855,16.608,0,33.849,7.005,33.849,25.454H-1501.3c-.1-5.487-2.7-8.056-6.664-8.056-3.649,0-6.135,2.1-6.135,5.429,0,4.028,3.279,5.37,8.092,7.3l9.785,3.911c13.7,5.488,24.277,11.5,24.277,27.381,0,19.032-15.55,26.212-35.437,26.212C-1528.328,403-1543.243,395.357-1543.243,376.792Z" transform="translate(1636.398 -232.014)" fill="#efc"/>
          <path id="Path_85" data-name="Path 85" d="M-1464.241,317.3h62.515v19.732h-34.484v11.5h28.189v19.85h-28.189v12.785h34.484v19.849h-62.515Z" transform="translate(1634.229 -232.067)" fill="#efc"/>
          <path id="Path_86" data-name="Path 86" d="M-1392.961,317.3h38.768c22.531,0,32.9,7.24,32.9,22.943,0,12.96-7.4,19.148-16.078,21.717,10.895,2.278,16.289,6.131,16.289,18.507v20.55h-28.137V379.589c0-6.247-3.6-9.107-10.313-9.107h-5.4v30.532h-28.031Zm33.69,35.9c6.082,0,10.366-2.335,10.366-9.282,0-6.248-4.019-8.523-10.366-8.523h-5.659V353.2Z" transform="translate(1632.272 -232.067)" fill="#efc"/>
          <path id="Path_87" data-name="Path 87" d="M-1311.73,317.3h38.768c22.531,0,32.9,7.24,32.9,22.943,0,12.96-7.4,19.148-16.078,21.717,10.9,2.278,16.29,6.131,16.29,18.507v20.55h-28.137V379.589c0-6.247-3.6-9.107-10.314-9.107h-5.395v30.532h-28.032Zm33.691,35.9c6.082,0,10.366-2.335,10.366-9.282,0-6.248-4.021-8.523-10.366-8.523h-5.659V353.2Z" transform="translate(1630.042 -232.067)" fill="#efc"/>
          <path id="Path_88" data-name="Path 88" d="M-1230.5,317.3h62.515v19.732h-34.484v11.5h28.19v19.85h-28.19v12.785h34.484v19.849H-1230.5Z" transform="translate(1627.812 -232.067)" fill="#efc"/>
          <path id="Path_89" data-name="Path 89" d="M-1638.6,432.169h14.53c9.94,0,15.161,5.719,15.161,15.251v36.218c0,9.672-4.97,15.2-16.424,15.2H-1638.6Zm13.266,54.817c2.316,0,3.369-.976,3.369-4.046V447.791c0-2.789-1.221-3.766-3.327-3.766h-.674v42.96Z" transform="translate(1639.015 -235.22)" fill="#efc"/>
          <path id="Path_90" data-name="Path 90" d="M-1603.318,485.172v-53h12.719v53.143c0,1.487.716,2.6,2.106,2.6s2.105-1.116,2.105-2.6V432.169h12.719v53c0,9.02-5.012,14.6-14.824,14.6C-1598.265,499.771-1603.318,494.192-1603.318,485.172Z" transform="translate(1638.047 -235.22)" fill="#efc"/>
          <path id="Path_91" data-name="Path 91" d="M-1528.525,499.864c-15.583-2.6-26.491-13.53-26.491-34.5,0-24.641,15.288-34.916,34.24-34.916s34.24,10,34.24,34.916c0,18.318-8.3,28.965-20.8,33.1l9.6,11.949h-26.027Zm7.748-14.739c8,0,10.067-9.066,10.067-19.759,0-10.6-2.065-19.574-10.067-19.574s-10.065,8.974-10.065,19.574C-1530.841,476.059-1528.778,485.125-1520.776,485.125Z" transform="translate(1636.721 -235.173)" fill="#efc"/>
          <path id="Path_92" data-name="Path 92" d="M-1478.334,477.78V432.169h22.321v43.983c0,4.742,2.359,7.207,6.571,7.207,4.3,0,6.57-2.464,6.57-7.207V432.169h22.322V477.78c0,14.553-9.435,22.782-28.891,22.782C-1468.984,500.562-1478.334,492.333-1478.334,477.78Z" transform="translate(1634.616 -235.22)" fill="#efc"/>
          <path id="Path_93" data-name="Path 93" d="M-1410.746,431.7h49.78v15.714h-27.459v9.159h22.448v15.808h-22.448v10.183h27.459v15.808h-49.78Zm17.268-16.458h21.9l-4.76,12.182h-19.752Z" transform="translate(1632.76 -234.756)" fill="#efc"/>
          <path id="Path_94" data-name="Path 94" d="M-1352.209,432.169h33.018c15.036,0,23.038,5.115,23.038,15.995,0,8.136-4.549,13.529-11.456,15.761,7.538,1.906,13.308,6.974,13.308,16.273,0,14.32-10.95,18.644-27.122,18.644h-30.786ZM-1325,457.694c4,0,6.612-1.952,6.612-6.323,0-4.045-2.738-5.533-6.528-5.533h-4.97v11.855Zm-.295,27.293c4.675,0,7.538-2.139,7.538-7.114,0-4.649-2.905-6.6-7.244-6.6h-4.885v13.716Z" transform="translate(1631.153 -235.22)" fill="#efc"/>
          <path id="Path_95" data-name="Path 95" d="M-1286.268,432.169h49.781v15.714h-27.459v9.159h22.447v15.808h-22.447v10.183h27.459v15.808h-49.781Z" transform="translate(1629.343 -235.22)" fill="#efc"/>
          <path id="Path_96" data-name="Path 96" d="M-1231.022,465.365c0-24.177,13.982-34.916,33.693-34.916,17.52,0,30.365,9.391,31.124,25.664h-23.922c-.085-5.764-2.148-10.322-7.581-10.322-6.907,0-9.139,7.393-9.139,19.481,0,12.6,2.106,19.853,8.844,19.853,5.98,0,7.918-5.579,8.255-11.949h23.543c-.8,19.016-14.573,27.292-32.134,27.292C-1217.924,500.468-1231.022,489.4-1231.022,465.365Z" transform="translate(1627.827 -235.173)" fill="#efc"/>
        </svg>

        <figcaption><h1 data-inview="fadeInUp" data-delay="400">Serre-toi !</h1></figcaption>
      </figure>
    </section>

    <!-- Infos -->
    <section class="informations">
      <h2 data-inview="fadeInUp" data-delay="100">Naturellement <strong>frais</strong></h2>
      <img data-inview="fadeInUp" data-delay="300" src="@/assets/img/produits.png" alt="Produits" class="produits" title="Produits">

      <p class="p1" data-inview="fadeInUp" data-delay="100">
        Choisir les fruits et légumes de <strong>SERRE</strong> du Québec, <br>
        c’est choisir de <strong>manger LOCAL</strong> À L’ANNÉE. <br>
        C’est aussi d’opter pour des produits <strong>FRAIS</strong>, <br>
        <strong>NATURELS</strong> ET DE <strong>HAUTE QUALITÉ</strong> <br>
        qui sont toujours de <strong>SAISON</strong>.
      </p>

      <p class="p2" data-inview="fadeInUp" data-delay="300">
        Joignez le mouvement initié par les producteurs en serre du Québec. Contribuez à développer l’autonomie alimentaire de la province tout en soutenant l’économie et les agriculteurs d’ici. Vous n’avez qu’à repérer les produits en épicerie et leur faire une petite (ou une grosse) place dans votre panier.
      </p>
    </section>

    <!-- Slider -->
    <section class="items-slider">
      <h2 data-inview="fadeInUp" data-delay="100">Découvrez les avantages des fruits et légumes de serre d’ici</h2>

      <div class="carousel-wrapper main-carousel">
        <div class="carousel-cell">
          <div class="img">
            <img data-inview="fadeInUp" data-delay="300" src="@/assets/img/slide2.png" alt="Piment" title="Piment">
          </div>
          <div class="content">
            <h3 data-inview="fadeInUp" data-delay="400">De la qualité quaTRE <br> <strong style="font-size: 111.5%;">saisons</strong></h3>
            <p data-inview="fadeInUp" data-delay="500">l’environnement contrôlé permet la culture de vos aliments préférés, peu importe la saison, grâce à un environnement contrôlé.</p>
          </div>
        </div>
        <div class="carousel-cell">
          <div class="img">
            <img src="@/assets/img/slide3.png" alt="Tomate" title="Tomate">
          </div>
          <div class="content">
            <h3>De la fraîcheur dans <br> <strong style="font-size: 101.5%;">l’assiette</strong></h3>
            <p>La proximité des produits de serre nous permet de mettre de la fraîcheur locale sur la table tout en réduisant l’empreinte environnementale liée au transport des aliments.</p>
          </div>
        </div>
        <div class="carousel-cell">
          <div class="img">
            <img src="@/assets/img/slide1.png" alt="Tomate" title="Tomate">
          </div>
          <div class="content">
            <h3>Du <strong style="font-size: 111.5%;">local</strong> <br> à <strong>l’année</strong></h3>
            <p>La consommation de produits cultivés en serre à l’année contribue fortement au développement de l’autonomie alimentaire du Québec tout en permettant de limiter notre dépendance aux fruits et légumes importés.</p>
          </div>
        </div>
      </div>
    </section>

    <!-- Stats -->
    <section id="chiffres" class="stats-slider">
      <div class="inbox-wrapper" data-inview="fadeInUp" data-delay="100">
        <img class="bg" src="@/assets/img/slider-bg.png" alt="" title="">

        <h2 data-inview="fadeInUp" data-delay="300">Les fruits et légumes <br> de serre du Québec en chiffres</h2>

        <div class="carousel-wrapper inbox-carousel">
          <div class="carousel-cell">
            <div class="content" data-inview="fadeInUp" data-delay="400">
              <h3>
                <span><strong>546</strong></span> <br>
                entreprises qui stimulent <br> l’économie
                <strong>locale</strong>
              </h3>
            </div>
          </div>
          <div class="carousel-cell">
            <div class="content">
              <h3>
                <span><strong>125</strong></span> <br>
                <strong style="font-size: 71.5%;">Hectares</strong> <br>
                de production
              </h3>
            </div>
          </div>
          <div class="carousel-cell">
            <div class="content">
              <h3>
                <span><strong>1300<small>+</small></strong></span> <br>
                <strong style="font-size: 141.5%;">emplois</strong> aux <br>
                quatre coins de la province
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Logos -->
    <section class="logos">
      <h2 data-inview="fadeInUp" data-delay="100">Une initiative de producteurs d’ici</h2>
      <!-- <img data-inview="fadeInUp" data-delay="300" src="@/assets/img/logos.png" alt="Logos" class="logos" title="Logos"> -->

      <div class="grid">
        <a href="https://www.lesserresroyales.com/" target="_blank" rel="noopener noreferrer" title="la-diva">
          <img src="@/assets/img/la-diva.png" alt="la-diva">
        </a>
        <a href="http://herbesgourmandes.com/" target="_blank" rel="noopener noreferrer" title="herbes-gourmandes">
          <img src="@/assets/img/herbes-gourmandes.png" alt="herbes-gourmandes">
        </a>
        <a href="https://excelserres.ca/" target="_blank" rel="noopener noreferrer" title="serrs-excel">
          <img src="@/assets/img/serrs-excel.png" alt="serrs-excel">
        </a>
        <a href="https://www.serrestoundra.com/" target="_blank" rel="noopener noreferrer" title="serres-toundra">
          <img src="@/assets/img/serres-toundra.png" alt="serres-toundra">
        </a>
        <a href="https://laituesmirabel.com/" target="_blank" rel="noopener noreferrer" title="serres-mirabel">
          <img src="@/assets/img/serres-mirabel.png" alt="serres-mirabel">
        </a>
        <a href="https://phdemers.com/" target="_blank" rel="noopener noreferrer" title="serres-demers">
          <img src="@/assets/img/serres-demers.png" alt="serres-demers">
        </a>
      </div>
    </section>

    <!-- Partenaires -->
    <section id="partenaires" class="partenaires-slider extends stats-slider">
      <div class="inbox-wrapper" data-inview="fadeInUp" data-delay="100">
        <img class="bg" src="@/assets/img/slider-bg2.png" alt="" title="">

        <h2 data-inview="fadeInUp" data-delay="300">En partenariat avec</h2>

        <div class="carousel-wrapper inbox-carousel2">
          <div class="carousel-cell">
            <div class="content" data-inview="fadeInUp" data-delay="400">
              <a href="https://www.alimentsduquebec.com/fr/" target="_blank" rel="noopener noreferrer" title="Logo Aliments du Québec"><img src="@/assets/img/aliemnts-qc.svg" alt="Logo Aliments du Québec" title="Aliments du Québec" class="logo"></a>
              <p>À titre d’organisme sans but lucratif, Aliments du Québec est fier de soutenir les agriculteurs d’ici en mettant de l’avant leurs produits depuis déjà plus de 25 ans. Une initiative au profit de l'économie d’ici et un sceau de confiance pour que nous en récoltions collectivement les fruits (et les légumes).</p>
            </div>
          </div>
          <div class="carousel-cell">
            <div class="content">
              <a href="https://www.hydroquebec.com/affaires/" target="_blank" rel="noopener noreferrer" title="Logo Hydro Québec"><img src="@/assets/img/hydro-q.png" alt="Logo Hydro Québec" title="Hydro Québec" class="logo"></a>
              <p>Nous produisons, transportons et distribuons de l’électricité. La satisfaction de nos clients est au cœur de nos préoccupations et nous encourageons l’efficacité énergétique. Notre électricité, propre et renouvelable, contribue à la lutte contre les changements climatiques et au renforcement d’une économie plus verte et plus forte.</p>
            </div>
          </div>
          <div class="carousel-cell">
            <div class="content">
              <a href="https://www.harnois.com/serres/" target="_blank" rel="noopener noreferrer" title="Logo Harnois"><img src="@/assets/img/harnois.png" alt="Logo Harnois" title="Logo Harnois" class="logo"></a>
              <p>En plus de soutenir les entreprises québécoises pour stimuler l’économie de la province, les Industries Harnois collaborent avec l’association aux producteurs en serre du Québec pour sensibiliser les communautés aux bienfaits de la  serriculture. Par ce partenariat, nous souhaitons développer l’industrie serricole et ainsi propulser l’autonomie alimentaire du Québec.</p>
            </div>
          </div>
          <div class="carousel-cell">
            <div class="content">
              <a href="https://www.desjardins.com/entreprises/conseils/index.jsp?utm_id=e-en-0-128162130171&campagne=e-en-0-128162130171&gclid=Cj0KCQjww4OMBhCUARIsAILndv5VeWHyhpQPXWxCDsDrhSUVORADoqo1mkPFh0hoO0an-r5a8inp12EaAkPuEALw_wcB&gclsrc=aw.ds" target="_blank" rel="noopener noreferrer" title="Logo Desjardins"><img src="@/assets/img/desjardins.png" alt="Logo Desjardins" title="Logo Desjardins" class="logo"></a>
              <p>Desjardins Entreprises s’investit dans des initiatives qui valorisent l’engagement social par un volet communautaire ou environnemental, en plus de s’impliquer dans le développement économique de la province. En s’associant aux producteurs en serre du Québec, Desjardins Entreprises souhaite encourager les compagnies québécoises, en plus de favoriser une économie stable, durable et indépendante.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Split -->
    <section id="apropos" class="split">
      <article class="row split1">
        <div class="col col-img">
          <img data-inview="fadeInUp" data-delay="100" src="@/assets/img/split1.png" alt="Légumes" title="Légumes">
        </div>
        <div class="col col-content">
          <h2 data-inview="fadeInUp" data-delay="300">Notre <strong>mission</strong></h2>
          <p data-inview="fadeInUp" data-delay="400">Offrir des fruits et légumes de serre produits de manière responsable et durable aux consommateurs québécois tout au long de l’année.</p>
          <a data-inview="fadeInUp" data-delay="600" href="https://www.serres.quebec/historique/" target="_blank" rel="nopener noreferrer" class="btn" title="En savoir +">En savoir +</a>
        </div>
      </article>
      <article class="row split2">
        <div class="col col-content">
          <h2 data-inview="fadeInUp" data-delay="300">Nos <strong>produits</strong></h2>
          <p data-inview="fadeInUp" data-delay="400">Peu importe la saison, découvrez la multitude de fruits et légumes que nous récoltons. Encouragez l’économie locale en ajoutant des produits d’ici à votre panier. <br> <br> <strong>Repérez-les en épicerie !</strong></p>
          <img data-inview="fadeInUp" data-delay="600" class="txt-svg" src="@/assets/img/final-text.svg" alt="Tomates" title="Tomates">
        </div>
        <div class="col col-img">
          <img data-inview="fadeInUp" data-delay="100" src="@/assets/img/split2.png" alt="Légumes" title="Légumes">
        </div>
      </article>
    </section>

    <!-- Footer -->
    <footer class="footer">
      <p><span data-inview="fadeInUp" data-delay="100">© Tous droits réservés - Les producteurs de fruits et légumes de serre du Québec</span></p>
      <!-- <div class="socials" data-inview="fadeInUp" data-delay="300">
        <a href="https://www.facebook.com/alimentsduquebec" target="_blank" rel="noopener noreferrer">
          <svg id="Group_52" data-name="Group 52" xmlns="http://www.w3.org/2000/svg" width="47.04" height="47.041" viewBox="0 0 47.04 47.041">
            <path id="Path_532" data-name="Path 532" d="M44.443,0H2.6A2.6,2.6,0,0,0,0,2.6V44.444a2.6,2.6,0,0,0,2.6,2.6H25.125V28.825H19v-7.1h6.13V16.488c0-6.075,3.709-9.385,9.13-9.385a50.843,50.843,0,0,1,5.477.279v6.35l-3.76,0c-2.947,0-3.516,1.4-3.516,3.456v4.532h7.031l-.919,7.1H32.456V47.039H44.442a2.6,2.6,0,0,0,2.6-2.6V2.6A2.6,2.6,0,0,0,44.443,0Z" transform="translate(0 -0.001)" fill="#efc"/>
          </svg>
        </a>
        <a href="https://www.instagram.com/alimentsqc/" target="_blank" rel="noopener noreferrer">
          <svg id="Group_53" data-name="Group 53" xmlns="http://www.w3.org/2000/svg" width="47.031" height="47.041" viewBox="0 0 47.031 47.041">
            <path id="Path_533" data-name="Path 533" d="M47.81,13.83a17.19,17.19,0,0,0-1.093-5.707,12.05,12.05,0,0,0-6.883-6.883A17.234,17.234,0,0,0,34.127.147C31.609.028,30.809,0,24.423,0s-7.186.028-9.695.138A17.192,17.192,0,0,0,9.022,1.231a11.477,11.477,0,0,0-4.172,2.72A11.582,11.582,0,0,0,2.139,8.114a17.234,17.234,0,0,0-1.093,5.706C.926,16.339.9,17.138.9,23.525s.028,7.186.138,9.695A17.189,17.189,0,0,0,2.13,38.927a12.049,12.049,0,0,0,6.883,6.883A17.237,17.237,0,0,0,14.719,46.9c2.508.111,3.308.138,9.695.138s7.186-.027,9.695-.138a17.186,17.186,0,0,0,5.706-1.093A12.032,12.032,0,0,0,46.7,38.927a17.246,17.246,0,0,0,1.093-5.707c.11-2.509.138-3.308.138-9.695S47.92,16.339,47.81,13.83ZM43.574,33.036a12.892,12.892,0,0,1-.809,4.365A7.8,7.8,0,0,1,38.3,41.867a12.942,12.942,0,0,1-4.365.808c-2.481.111-3.225.138-9.5.138s-7.03-.027-9.5-.138a12.889,12.889,0,0,1-4.365-.808,7.24,7.24,0,0,1-2.7-1.755,7.315,7.315,0,0,1-1.755-2.7A12.939,12.939,0,0,1,5.3,33.045c-.111-2.481-.138-3.226-.138-9.5s.027-7.03.138-9.5a12.885,12.885,0,0,1,.808-4.365,7.15,7.15,0,0,1,1.765-2.7,7.3,7.3,0,0,1,2.7-1.755,12.946,12.946,0,0,1,4.365-.809c2.481-.11,3.226-.138,9.5-.138s7.03.028,9.5.138a12.891,12.891,0,0,1,4.365.809,7.232,7.232,0,0,1,2.7,1.755,7.312,7.312,0,0,1,1.755,2.7,12.944,12.944,0,0,1,.809,4.365c.11,2.481.138,3.225.138,9.5S43.684,30.555,43.574,33.036Z" transform="translate(-0.898 0)" fill="#efc"/>
            <path id="Path_534" data-name="Path 534" d="M137.263,124.281a12.084,12.084,0,1,0,12.084,12.084A12.087,12.087,0,0,0,137.263,124.281Zm0,19.923a7.839,7.839,0,1,1,7.839-7.839A7.84,7.84,0,0,1,137.263,144.2Z" transform="translate(-113.738 -112.84)" fill="#efc"/>
            <path id="Path_535" data-name="Path 535" d="M367.9,91.267a2.821,2.821,0,1,1-2.821-2.821A2.821,2.821,0,0,1,367.9,91.267Z" transform="translate(-328.995 -80.304)" fill="#efc"/>
          </svg>
        </a>
      </div> -->
    </footer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Flickity from 'flickity'

import initInViewAnimations from '@/misc/in-view-animations'

export default {
  name: 'Home',

  head() {
    return {
      title: {
        inner: 'Les fruits et légumes de serre du Québec'
      },
      meta: [
        {
          name: 'description',
          content: `Joignez le mouvement initié par les Producteurs en serre du Québec, Aliments du Québec et Hydro-Québec. Contribuez à développer l’autonomie alimentaire de la province tout en soutenant l’économie et les agriculteurs d’ici. Vous n’avez qu’à repérer les produits en épicerie et leur faire une petite (ou une grosse) place dans votre panier.`,
          id: 'desc'
        },
        { name: 'application-name', content: `SerreToi` },
        { name: 'twitter:title', content: `Les fruits et légumes de serre du Québec` },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: `Joignez le mouvement initié par les Producteurs en serre du Québec, Aliments du Québec et Hydro-Québec. Contribuez à développer l’autonomie alimentaire de la province tout en soutenant l’économie et les agriculteurs d’ici. Vous n’avez qu’à repérer les produits en épicerie et leur faire une petite (ou une grosse) place dans votre panier.`
        },
        // Facebook / Open Graph
        { property: 'og:title', content: `Les fruits et légumes de serre du Québec` },
        { property: 'og:site_name', content: `SerreToi` },
        {
          property: 'og:description',
          content: `Joignez le mouvement initié par les Producteurs en serre du Québec, Aliments du Québec et Hydro-Québec. Contribuez à développer l’autonomie alimentaire de la province tout en soutenant l’économie et les agriculteurs d’ici. Vous n’avez qu’à repérer les produits en épicerie et leur faire une petite (ou une grosse) place dans votre panier.`
        },
        // Og Image
        {
          p: 'og:image',
          c: '/og.png'
        },
        {
          name: 'twitter:image',
          content: '/og.png'
        }
      ]
    }
  },

  computed: mapState('app', ['appTitle']),

  mounted() {
    // Page load buffer
    setTimeout(() => {
        const mainCarousel = document.querySelector('.main-carousel')
        this.mainCarouselFlk = new Flickity(mainCarousel, {
            cellAlign: 'center',
            wrapAround: false,
            lazyLoad: true,
            imagesLoaded: true,
            prevNextButtons: false,
            pageDots: true
        })

        const inboxCarousel = document.querySelector('.inbox-carousel')
        this.inboxCarouselFlk = new Flickity(inboxCarousel, {
            cellAlign: 'center',
            wrapAround: true,
            lazyLoad: true,
            imagesLoaded: true,
            prevNextButtons: false,
            pageDots: true
        })

        const inboxCarousel2 = document.querySelector('.inbox-carousel2')
        this.inboxCarousel2Flk = new Flickity(inboxCarousel2, {
            cellAlign: 'center',
            wrapAround: true,
            lazyLoad: true,
            imagesLoaded: true,
            prevNextButtons: false,
            pageDots: true
        })

        initInViewAnimations()
    }, 1175);
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
