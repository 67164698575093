<template>
  <div id="app">
    <nav-bar></nav-bar>
    <div class="main-wrapper">
      <router-view />
    </div>
  </div>
</template>
<script>
import NavBar from '@/components/NavBar'

export default {
  components: { NavBar }
}
</script>

<style lang="scss">
  @import '@/theme/variables.scss';
  @import '@/theme/animations-layer.scss';
  @import '@/theme/global.scss';
  @import '@/theme/typo.scss';
  @import '@/theme/site.scss';
</style>
