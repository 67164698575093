<template>
  <header class="navbar">
    <nav class="nav-links">
      <a class="nav-link" href="#accueil" v-scroll-to:-55 title="Accueil">Accueil</a>
      <a class="nav-link" href="#chiffres" v-scroll-to:-55 title="En chiffres">En chiffres</a>
      <a class="nav-link" href="#partenaires" v-scroll-to:-55 title="Partenaires">Partenaires</a>
      <a class="nav-link" href="#apropos" v-scroll-to:-55 title="À propos">À propos</a>

      <a class="nav-link nav-link-lang" href="#" title="En">&nbsp;</a>

      <div id="hamburger" @click.prevent.stop="() => { mobileMenuOpen = !mobileMenuOpen }" :class="{ open: mobileMenuOpen }">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </nav>

    <!-- Mobile Menu -->
    <div class="mobile-menu-wrap" :class="{ open: mobileMenuOpen }">
      <nav @click="closeAfterClick()">
        <a href="#accueil" v-scroll-to:-45 class="nav-link" title="Accueil" >Accueil</a>
        <a href="#chiffres" v-scroll-to:-45 class="nav-link" title="En Chiffres">En Chiffres</a>
        <a href="#partenaires" v-scroll-to:-45 class="nav-link" title="Partenaires">Partenaires</a>
        <a href="#apropos" v-scroll-to:-45 class="nav-link" title="À propos">À propos</a>
      </nav>
      <!-- <div class="social">
        <a href="#" title="Facebook link" target="_blank" rel="noopener noreferrer">
          <svg id="Group_52" data-name="Group 52" xmlns="http://www.w3.org/2000/svg" width="47.04" height="47.041" viewBox="0 0 47.04 47.041">
            <path id="Path_532" data-name="Path 532" d="M44.443,0H2.6A2.6,2.6,0,0,0,0,2.6V44.444a2.6,2.6,0,0,0,2.6,2.6H25.125V28.825H19v-7.1h6.13V16.488c0-6.075,3.709-9.385,9.13-9.385a50.843,50.843,0,0,1,5.477.279v6.35l-3.76,0c-2.947,0-3.516,1.4-3.516,3.456v4.532h7.031l-.919,7.1H32.456V47.039H44.442a2.6,2.6,0,0,0,2.6-2.6V2.6A2.6,2.6,0,0,0,44.443,0Z" transform="translate(0 -0.001)" fill="#efc"/>
          </svg>
        </a>
        <a href="#" title="Instagram link" target="_blank" rel="noopener noreferrer">
          <svg id="Group_53" data-name="Group 53" xmlns="http://www.w3.org/2000/svg" width="47.031" height="47.041" viewBox="0 0 47.031 47.041">
            <path id="Path_533" data-name="Path 533" d="M47.81,13.83a17.19,17.19,0,0,0-1.093-5.707,12.05,12.05,0,0,0-6.883-6.883A17.234,17.234,0,0,0,34.127.147C31.609.028,30.809,0,24.423,0s-7.186.028-9.695.138A17.192,17.192,0,0,0,9.022,1.231a11.477,11.477,0,0,0-4.172,2.72A11.582,11.582,0,0,0,2.139,8.114a17.234,17.234,0,0,0-1.093,5.706C.926,16.339.9,17.138.9,23.525s.028,7.186.138,9.695A17.189,17.189,0,0,0,2.13,38.927a12.049,12.049,0,0,0,6.883,6.883A17.237,17.237,0,0,0,14.719,46.9c2.508.111,3.308.138,9.695.138s7.186-.027,9.695-.138a17.186,17.186,0,0,0,5.706-1.093A12.032,12.032,0,0,0,46.7,38.927a17.246,17.246,0,0,0,1.093-5.707c.11-2.509.138-3.308.138-9.695S47.92,16.339,47.81,13.83ZM43.574,33.036a12.892,12.892,0,0,1-.809,4.365A7.8,7.8,0,0,1,38.3,41.867a12.942,12.942,0,0,1-4.365.808c-2.481.111-3.225.138-9.5.138s-7.03-.027-9.5-.138a12.889,12.889,0,0,1-4.365-.808,7.24,7.24,0,0,1-2.7-1.755,7.315,7.315,0,0,1-1.755-2.7A12.939,12.939,0,0,1,5.3,33.045c-.111-2.481-.138-3.226-.138-9.5s.027-7.03.138-9.5a12.885,12.885,0,0,1,.808-4.365,7.15,7.15,0,0,1,1.765-2.7,7.3,7.3,0,0,1,2.7-1.755,12.946,12.946,0,0,1,4.365-.809c2.481-.11,3.226-.138,9.5-.138s7.03.028,9.5.138a12.891,12.891,0,0,1,4.365.809,7.232,7.232,0,0,1,2.7,1.755,7.312,7.312,0,0,1,1.755,2.7,12.944,12.944,0,0,1,.809,4.365c.11,2.481.138,3.225.138,9.5S43.684,30.555,43.574,33.036Z" transform="translate(-0.898 0)" fill="#efc"/>
            <path id="Path_534" data-name="Path 534" d="M137.263,124.281a12.084,12.084,0,1,0,12.084,12.084A12.087,12.087,0,0,0,137.263,124.281Zm0,19.923a7.839,7.839,0,1,1,7.839-7.839A7.84,7.84,0,0,1,137.263,144.2Z" transform="translate(-113.738 -112.84)" fill="#efc"/>
            <path id="Path_535" data-name="Path 535" d="M367.9,91.267a2.821,2.821,0,1,1-2.821-2.821A2.821,2.821,0,0,1,367.9,91.267Z" transform="translate(-328.995 -80.304)" fill="#efc"/>
          </svg>
        </a>
      </div> -->
    </div>
  </header>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data() {
    return {
      mobileMenuOpen: false,
    }
  },

  computed: {
    ...mapState('app', ['networkOnLine', 'appTitle', 'appShortTitle', 'panelIsOpen'])
  },

  methods: {
    closeAfterClick() {
      setTimeout(() => {
        this.mobileMenuOpen = false
      }, 55)
    },
    ...mapActions(['togglePanel'])
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
